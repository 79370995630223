.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    	-webkit-animation: heartbeat 10s ease infinite both;
	        animation: heartbeat 10s ease infinite both;
          margin: 1rem auto;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.support_wrapper {
    border-radius: 2rem;
    margin: 2rem auto;
    padding: 0rem 6rem;
    display: flex;
}

.support-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
}

.support-container_header {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.support-container_header p {
    color: white;
    font-size: 18px;
    line-height: 14px;
}

.support-container_header h3 {
    color: #fff;
    font-size: 48px;
    margin: 0;
    margin-bottom: 2rem;
}

.support-container_description p {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    margin: 2rem auto;
}
.support-container_description {
  display: flex;
  flex: 2;
}

.Norsk-Tipping-Logo {
    height: 80px;
    width: 120px;
    filter: grayscale(1);
    margin: auto;
}

.support-container_QR {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 1rem;
    border-radius: 1rem;
}


@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

